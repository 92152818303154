
// Import our top-level sass file.
import './assets/css/style.scss'
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Cookies from "js-cookie"
import { Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import Reducers from "./store/reducers/index";


//  Routing Includes

import Login from "./components/login/login";
const history = createBrowserHistory();


const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25
        })) ||
    compose;
const store = createStore(Reducers, composeEnhancers(applyMiddleware(thunk)));

const isAuthenticated = () => {
    return !!Cookies.get("Logintoken");
}



const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated() === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

const routing = (
    <Provider store={store} >
        <Router history={history}>
            <div>
                <Route exact path="/" component={() => { return <Login /> }} name="login" />
                <Route exact path="/login" component={() => { return <Login /> }} name="login" />
            </div>
        </Router>
    </Provider>
);

ReactDOM.render(routing, document.querySelector('#app'));
serviceWorker.unregister();