import React, { PureComponent } from "react";
import "../../assets/css/style.scss";
import { Input, Button } from "antd";

class login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12 height100vh">
          <div className="row">
            <div className="col-md-3 dark-background height100vh"></div>
            <div className="col-md-9 mid-center padding50 height100vh">
              <div className="row">
                <div className="col-md-12 md200">
                  <h2 className="heading">Sign in to cloud</h2>
                </div>
                <div className="col-md-5 md20 nopadding">
                  <form className="row nomargin">
                    <div className="col-md-12">
                      <Input placeholder="Phone Number or EmailID"></Input>
                    </div>

                    <div className="col-md-12 md20">
                      <Input placeholder="Password"></Input>
                      <a
                        className="blue-text"
                        style={{ float: "right", marginTop: "10px" }}
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <div className="col-md-12 md20">
                      <Button className="btn btn-primary">Login</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default login;
